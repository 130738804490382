.command-center {
  display: flex;
  height: 100%;
}

:fullscreen,
::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

.table-max-height {
  max-height: 440px;
}

.table-header-cell {
  background: #004c91;
}

.show-more {
  cursor: pointer;
}
