.history-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px 15px;
}

.duration-interval {
  padding: 12px;
}
