/* required later */
/* .homekare {
  width: 95%;
  height: 100vh;
  flex: 1;
  overflow-y: auto;
}

@media screen and (width > 2560px) {
  .homekare {
    width: 97%;
  }
}

@media screen and (max-width: 2560px) {
  .homekare {
    width: 97%;
  }
}

@media screen and (max-width: 1440px) {
  .homekare {
    width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .homekare {
    width: 93%;
  }
}

@media screen and (max-width: 768px) {
  .homekare {
    width: 92%;
  }
}

@media screen and (max-width: 425px) {
  .homekare {
    width: 85%;
  }
} */

.ambulance-container {
  width: 95%;
  height: 100vh;
  flex: 1;
  overflow-y: auto;
}

@media screen and (width > 2560px) {
  .ambulance-container {
    width: 97%;
  }
}

@media screen and (max-width: 2560px) {
  .ambulance-container {
    width: 97%;
  }
}

@media screen and (max-width: 1440px) {
  .ambulance-container {
    width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .ambulance-container {
    width: 93%;
  }
}

@media screen and (max-width: 768px) {
  .ambulance-container {
    width: 92%;
  }
}

@media screen and (max-width: 425px) {
  .ambulance-container {
    width: 85%;
  }
}

.select-ambulance {
  width: 100%;
  height: 65px;
  border-radius: 0px !important;
  background-color: #f5f6f8;
}

.prorithm-heading {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  border-bottom: 1px solid #1b5299;
}

.prorithm-vitals-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 10px;
}

.prorithm-vital-heading {
  background-color: #1b5299;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
}

.vital-title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.vital-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.prorithm-vital-value {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #7da5da;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
}

.vitals-gauge {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #7da5da;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 0px;
}

.gauge-text {
  color: #f5f6f8;
}

.device-section {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  align-items: center;
  text-align: center;
}

.device-card {
  height: 60px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid #d5d5d5;
  cursor: pointer;
  align-items: center;
}

.device-card-selected {
  border-left: 2px solid #1b5299;
  border-right: 2px solid #1b5299;
  border-bottom: 2px solid #1b5299;
  align-items: center;
}
.device-card:hover {
  background-color: #f7f9f9;
}

.device-image {
  margin: 3px 5px;
}

.device-logo {
  color: #555;
}

.no-device-available {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 60px;
}

.no-device-selected {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #181717;
  border: 1px solid #888;
}

.location-icon {
  color: #1b5299;
}
