.alert-button {
  background-color: #1b5299 !important;
}

.alert-details-container {
  display: flex !important;
  justify-content: space-between !important;
  border: 1px solid rgb(175, 174, 174) !important;
  border-radius: 10px !important;
  margin: 15px 0px !important;
}

.alert-details {
  display: flex !important;
  justify-content: space-between !important;
  gap: 10px !important;
  width: 100%;
}

.close-icon {
  margin-left: 10px;
}

.clicked {
  background-color: rgba(27, 82, 153, 0.1) !important;
}

.alert-badge {
  margin-top: -60px !important;
}

.alert-vitals {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  font-weight: 600 !important;
}

.alert-vitals-for-extreme {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  color: #ff6600;
  font-weight: 600 !important;
}

.alert-vitals-for-normal {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  color: #ff0000;
  font-weight: 600 !important;
}

.alert-subDetails {
  font-weight: 600 !important;
}
