.alert-button {
  background-color: #1b5299 !important;
}

#thop-notifications {
  position: relative;
}

.notification-details {
  display: flex !important;
  justify-content: space-between !important;
  border: 1px solid rgb(175, 174, 174) !important;
  border-radius: 10px !important;
  margin: 15px 0px !important;
  padding: 10px;
  width: 100%;
}

.notification-details .MuiTypography-body1 {
  font-weight: 600 !important;
}

.notification-details .MuiTypography-body2 {
  font-size: 0.875rem;
  color: #333;
}

.notification-details .MuiTypography-caption {
  color: #999;
  font-size: 0.75rem;
}

.notification-details .MuiIconButton-root {
  margin-left: 10px;
}

.unread {
  background-color: rgba(27, 82, 153, 0.1) !important;
}

.notification-badge {
  margin-top: -60px !important;
}

.notification-timestamp {
  font-size: 0.75rem;
  color: #888;
}

.notification-vitals {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  font-weight: 600 !important;
}

.notification-vitals-for-extreme {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  color: #ff6600;
  font-weight: 600 !important;
}

.notification-vitals-for-normal {
  padding: 7px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  color: #ff0000;
  font-weight: 600 !important;
}

.notification-subDetails {
  font-weight: 600 !important;
}

.notificationItem {
  padding: 15px;
  border: 1px solid #dcdcdc;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 10px;
  box-shadow: 6px 6px 12px #5a5a5a, -6px -6px 12px #ffffff;
}

.notificationItem:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.patientInfo {
  margin: 5px 0;
  font-weight: bold;
  color: #1565c0;
  font-size: 16px;
}

.statusAccepted {
  margin: 5px 0;
  color: green;
  font-size: 14px;
}

.statusRejected {
  margin: 5px 0;
  color: red;
  font-size: 14px;
}

.rejectionReason {
  margin: 5px 0;
  color: #757575;
  font-size: 14px;
}

.notification-list-item {
  display: block !important;
  width: 100%;
  border-radius: 10px;
}

.notification-list-item-container {
  padding: 20px;
}

.alertDrawer .MuiDrawer-paper {
  width: 30%;
}

@media (max-width: 950px) {
  .alertDrawer .MuiDrawer-paper {
    width: 90%;
  }
}
