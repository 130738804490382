.patient-logo {
  color: #555;
}

.patient-card {
  height: 60px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid #d5d5d5;
  cursor: pointer;
}

.patient-card:hover {
  background-color: #f7f9f9;
}
