.normal-mode {
  background-color: #004c91;
}

.sidebar-container {
  position: sticky;
  top: 0;
  width: 5%;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (width > 2560px) {
  .sidebar-container {
    width: 3%;
  }
}

@media screen and (max-width: 2560px) {
  .sidebar-container {
    width: 3%;
  }
}

@media screen and (max-width: 1440px) {
  .sidebar-container {
    width: 5%;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-container {
    width: 7%;
  }
}

@media screen and (max-width: 768px) {
  .sidebar-container {
    width: 8%;
  }
}

@media screen and (max-width: 425px) {
  .sidebar-container {
    width: 15%;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
}

.layout-button {
  color: white;
}

.logo {
  color: white;
  align-items: center;
  margin: 30px 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.logo-button {
  background-color: #d5d5d5;
}

.logo:hover {
  transform: scale(1.3);
}

.sidebar-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: white;
  padding: 20px 0px;
}

.sidebar-button:hover {
  transform: scale(1.1);
}

.sidebar-button-clicked {
  border-left: 5px solid rgb(255, 166, 0);
}

::-webkit-scrollbar {
  width: 1px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-bottom-button-set {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
