.patient-details {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 15px;
  justify-content: flex-start;
  border: 1px solid #1b5299;
  border-radius: 10px;
  background-color: #f8f8f8;
}

.patient-demographic-detail {
  margin: 10px 20px;
}

.patient-demographic-type {
  color: #555;
}

.patient-demographic-name {
  color: #1b5299;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
}

.patient-demographic-value {
  font-weight: bold;
  color: #181717;
  text-overflow: ellipsis;
}
